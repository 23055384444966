import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from "react-redux";
import { getProfile } from "./actions/user";

const Layout = ({ getProfile, children }) => {
  const history = useHistory();

  useEffect(async () => {
    let response = null;
    try {
      response = await getProfile();
    } catch (error) {
      console.log(error);
      history.push("/login");
    }
  }, []);

  return(
    <>
      {children}
    </>
  );
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
  getProfile
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);

