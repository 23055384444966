import _remove from 'lodash/remove';
import * as IndustryActions from '../actions/industry';
import reducerWithActionMap from "../utils/reducerWithActionsMap";

const initialState = {
  isAuthenticated: false,
  industryList: {
    isFetching: false,
    isFetched: false,
    listData: [],
    total: 0,
  },
  industryDetails: {
    isFetching: false,
    isFetched: false,
    industryData: [],
  },
};

const setIndustryListFetching = fetching => state => ({
  ...state,
  industryList: {
    ...state.industryList,
    isFetching: fetching,
  },
});

const setIndustryList = (state, { payload: { listData, total } }) => ({
  ...state,
  industryList: {
    ...state.industryList,
    listData,
    total,
    isFetching: false,
    isFetched: true,
  },
});

const actionMap = {
  [IndustryActions.LIST_INDUSTRY_REQUEST]: setIndustryListFetching(true),
  [IndustryActions.LIST_INDUSTRY_SUCCESS]: setIndustryList,
  [IndustryActions.LIST_INDUSTRY_FAILURE]: setIndustryListFetching(false),
};

export default reducerWithActionMap(actionMap, initialState);


