import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Header from "../../components/Header";
import BgImage from "../../components/BgImage";
import { countIssues } from "../../actions/issue";
import { Bar } from "react-chartjs-2";
import moment from "moment";

const StatisticsByAge = ({ countIssues }) => {
  const [age, setAge] = useState([]);

  const state = {
    labels: ["20-30", "30-40", "40-50", "50-60", "60+"],
    datasets: [
      {
        backgroundColor: "#00408A",
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: age,
      },
    ],
  };

  useEffect(() => {
    statsByAge();
  }, []);

  const statsByAge = async () => {
    let range1 = await countIssues({
      "reporter.birth_lt": moment().subtract(20, "years").toDate(),
      "reporter.birth_gte": moment().subtract(30, "years").toDate(),
    });
    let range2 = await countIssues({
      "reporter.birth_lt": moment().subtract(30, "years").toDate(),
      "reporter.birth_gte": moment().subtract(40, "years").toDate(),
    });
    let range3 = await countIssues({
      "reporter.birth_lt": moment().subtract(40, "years").toDate(),
      "reporter.birth_gte": moment().subtract(50, "years").toDate(),
    });
    let range4 = await countIssues({
      "reporter.birth_lt": moment().subtract(50, "years").toDate(),
      "reporter.birth_gte": moment().subtract(60, "years").toDate(),
    });
    let range5 = await countIssues({
      "reporter.birth_lt": moment().subtract(60, "years").toDate(),
    });
    setAge([range1, range2, range3, range4, range5]);
  };

  // const statsByCategory = async () => {
  //   let categories = await listCategories();

  //   let promises = [];
  //   for (let i = 0; i < categories.length; ++i) {
  //     promises.push(countCategory(categories[i].id));
  //   }
  //   const counts = await Promise.all(promises);
  //   for (let i = 0; i < categories.length; ++i) {
  //     categories[i].reportsCount = counts[i];
  //   }
  //   setCategories(categories);
  // };

  return (
    <>
      <Header></Header>
      <BgImage></BgImage>
      <div className="container mx-auto">
        <div className="text-2xl text-center mt-10 uppercase">
          Статистика о узрасту
        </div>
        <Bar
          data={state}
          options={{
            legend: {
              display: false,
            },
          }}
        />
      </div>
    </>
  );
};

const mapDispatchToProps = {
  countIssues,
};

export default connect(null, mapDispatchToProps)(StatisticsByAge);
