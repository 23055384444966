import * as DtiActions from "../actions/dtiLocation";
import reducerWithActionMap from "../utils/reducerWithActionsMap";

const initialState = {
  locationsList: {
    isFetching: false,
    isFetched: false,
    listData: [],
  },
};

const setLocationsListFetching = (fetching) => (state) => ({
  ...state,
  locationsList: {
    ...state.locationsList,
    isFetching: fetching,
  },
});

const setLocationsList = (state, { payload: { listData } }) => ({
  ...state,
  locationsList: {
    ...state.locationsList,
    isFetching: false,
    isFetched: true,
    listData,
  },
});

const actionMap = {
  [DtiActions.LIST_LOCATIONS_REQUEST]: setLocationsListFetching(true),
  [DtiActions.LIST_LOCATIONS_SUCCESS]: setLocationsList,
  [DtiActions.LIST_LOCATIONS_FAILURE]: setLocationsListFetching(false),
};

export default reducerWithActionMap(actionMap, initialState);
