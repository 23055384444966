import getAxiosInstance from '../config/http';

export const LIST_CATEGORY_REQUEST = 'CATEGORY@LIST_CATEGORY_REQUEST';
export const LIST_CATEGORY_SUCCESS = 'CATEGORY@LIST_CATEGORY_SUCCESS';
export const LIST_CATEGORY_FAILURE = 'CATEGORY@LIST_CATEGORY_FAILURE';

export const listCategories = (params) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_CATEGORY_REQUEST,
  });
  try{
    const categoryData = await http.get('/right-categories', {params});
    dispatch({
      type: LIST_CATEGORY_SUCCESS,
      payload: {
        listData: categoryData.data,
      },
    });
    return categoryData.data;
  }catch(e){
    dispatch({
      type: LIST_CATEGORY_FAILURE,
    });
  }
};
