import getAxiosInstance from "../config/http";

export const LOGIN_REQUEST = "USER@LOGIN_REQUEST";
export const LOGIN_SUCCESS = "USER@LOGIN_SUCCESS";
export const LOGIN_FAILURE = "USER@LOGIN_FAILURE";

export const login = (userData) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: LOGIN_REQUEST,
  });
  try {
    const { data } = await http.post("/auth/local", userData);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: {
        userData: data,
      },
    });
    return data;
  } catch (e) {
    dispatch({
      type: LOGIN_FAILURE,
    });
    throw e;
  }
};

export const PROFILE_REQUEST = "USER@PROFILE_REQUEST";
export const PROFILE_SUCCESS = "USER@PROFILE_SUCCESS";
export const PROFILE_FAILURE = "USER@PROFILE_FAILURE";

export const getProfile = () => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: PROFILE_REQUEST,
  });
  try {
    const { data } = await http.get("/users/me");
    dispatch({
      type: PROFILE_SUCCESS,
      payload: {
        userData: data,
      },
    });
  } catch (e) {
    dispatch({
      type: PROFILE_FAILURE,
    });
    throw e;
  }
};

export const CREATE_USER_REQUEST = "USER@CREATE_USER_REQUEST";
export const CREATE_USER_SUCCESS = "USER@CREATE_USER_SUCCESS";
export const CREATE_USER_FAILURE = "USER@CREATE_USER_FAILURE";

export const createUser = (userData) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: CREATE_USER_REQUEST,
  });
  try {
    const { data } = await http.post("/users", userData);
    dispatch({
      type: CREATE_USER_SUCCESS,
      payload: {
        userData: data,
      },
    });
    return data;
  } catch (e) {
    dispatch({
      type: CREATE_USER_FAILURE,
    });
    throw e;
  }
};

export const LIST_USER_REQUEST = "USER@LIST_USER_REQUEST";
export const LIST_USER_SUCCESS = "USER@LIST_USER_SUCCESS";
export const LIST_USER_FAILURE = "USER@LIST_USER_FAILURE";

export const listUsers = (params) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_USER_REQUEST,
  });
  try {
    const userData = await http.get("/users", {params});
    dispatch({
      type: LIST_USER_SUCCESS,
      payload: {
        listData: userData.data,
      },
    });
  } catch (e) {
    dispatch({
      type: LIST_USER_FAILURE,
    });
  }
};

export const FETCH_USER_REQUEST = "USER@FETCH_USER_REQUEST";
export const FETCH_USER_SUCCESS = "USER@FETCH_USER_SUCCESS";
export const FETCH_USER_FAILURE = "USER@FETCH_USER_FAILURE";

export const fetchUser = (userId) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: FETCH_USER_REQUEST,
  });
  try {
    const userData = await http.get(`/users/${userId}`);
    dispatch({
      type: FETCH_USER_SUCCESS,
      payload: {
        userData: userData.data,
      },
    });
  } catch (e) {
    dispatch({
      type: FETCH_USER_FAILURE,
    });
  }
};

export const UPDATE_USER_REQUEST = "USER@UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "USER@UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "USER@UPDATE_USER_FAILURE";

export const updateUser = (id, userData) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: UPDATE_USER_REQUEST,
  });
  try {
    const data = await http.put(`/users/${id}`, userData);
    dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: {
        userData: data,
      },
    });
  } catch (e) {
    dispatch({
      type: UPDATE_USER_FAILURE,
    });
  }
};

export const DELETE_USER_REQUEST = "USER@DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "USER@DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "USER@DELETE_USER_FAILURE";

export const deleteUser = (id) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: DELETE_USER_REQUEST,
  });
  try {
    const data = await http.delete(`/users/${id}`);
    dispatch({
      type: DELETE_USER_SUCCESS,
      payload: {
        userId: id,
      },
    });
    return data;
  } catch (e) {
    dispatch({
      type: DELETE_USER_FAILURE,
    });
  }
};

export const RESET_PASSWORD_REQUEST = "USER@RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "USER@RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "USER@RESET_PASSWORD_FAILURE";

export const resetPassword = (userData) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: RESET_PASSWORD_REQUEST,
  });
  try {
    const { data } = await http.post("/auth/reset-password", userData);
    dispatch({
      type: RESET_PASSWORD_SUCCESS,
      payload: {
        userData: data,
      },
    });
    return data;
  } catch (e) {
    dispatch({
      type: RESET_PASSWORD_FAILURE,
    });
    throw e;
  }
};

export const LOGOUT_REQUEST = "USER@LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "USER@LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "USER@LOGOUT_FAILURE";

export const logout = () => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: LOGOUT_REQUEST,
  });
  try {
    const { data } = await http.get("/auth/logout");
    dispatch({
      type: LOGOUT_SUCCESS,
      payload: {
        userData: data,
      },
    });
  } catch (e) {
    dispatch({
      type: LOGOUT_FAILURE,
    });
    throw e;
  }
};

export const COUNT_USER_REQUEST = 'USER@COUNT_USER_REQUEST';
export const COUNT_USER_SUCCESS = 'USER@COUNT_USER_SUCCESS';
export const COUNT_USER_FAILURE = 'USER@COUNT_USER_FAILURE';

export const countUsers = (params) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: COUNT_USER_REQUEST,
  });
  try{
    const userData = await http.get('/users/count', {params});
    dispatch({
      type: COUNT_USER_SUCCESS,
      payload: {
        total: userData.data,
      },
    });
    return userData.data;
  }catch(e){
    dispatch({
      type: COUNT_USER_FAILURE,
    });
  }
};
