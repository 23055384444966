import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { useParams, useHistory } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { connect } from "react-redux";
import Header from "../../components/Header";
import BgImage from "../../components/BgImage";
import { createUser, updateUser, fetchUser } from "../../actions/user";
import { getUserDetails } from "../../selectors/user";
import Layout from "../../Layout";

const AdminSchema = yup.object().shape({
  firstName: yup.string().required("Ова поле е задолжително"),
  familyName: yup.string().required("Ова поле е задолжително"),
  email: yup.string().required("Ова поле е задолжително"),
  password: yup
    .string()
    .min(4, "Премногу кратка лозинка!")
    .max(10, "Премногу долга лозинка!")
    .required("Ова поле е задолжително"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Лозинките мора да се совпаѓаат"),
});

const startValues = {
  firstName: "",
  familyName: "",
  email: "",
  password: "",
  confirmPassword: "",
  // role: {
  //   _id: ""
  // }
};

const AddAdministrator = ({
  createUser,
  updateUser,
  fetchUser,
  userDetails,
}) => {
  const [initialValues, setInitialValues] = useState(startValues);

  let { id } = useParams();
  const history = useHistory();
  const {
    userData,
    isFetching: userDataIsFetching,
    isFetched: userDataIsFetched,
  } = userDetails;

  useEffect(() => {
    if (id) {
      fetchUser(id);
    }
  }, []);

  const formSubmit = async (values) => {
    let admin = null;
    console.log(values);
    try {
      if (id) {
        admin = await updateUser(id, values);
      } else {
        admin = await createUser({
          username: values.email,
          ...values,
        });
      }
      history.push("/administrators");
    } catch (e) {
      console.log(e);
      // alert("Something went wrong, please try again");
    }
  };

  useEffect(() => {
    if (id && userData) {
      console.log(userData);
      setInitialValues({
        firstName: userData.firstName || "",
        familyName: userData.familyName || "",
        email: userData.email || "",
        password: userData.password || "",
        confirmPassword: userData.confirmPassword || "",
      });
    }
  }, [userData]);

  return (
    <Layout>
      <Header></Header>
      <BgImage></BgImage>
      <div className="container w-2/4 m-0 mx-auto">
        <div className="text-2xl text-center mt-10 uppercase">
          Додади администратор
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={AdminSchema}
          validateOnChange={false}
          enableReinitialize={true}
          onSubmit={formSubmit}
        >
          <Form>
            <div className="">
              <div className="mt-10">
                <div className="relative">
                  <label for="firstName" className="text-gray-700">
                    Име
                    <span className="text-red required-dot">*</span>
                  </label>
                  <Field
                    name="firstName"
                    placeholder="Внесете име"
                    type="text"
                    className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                  ></Field>
                </div>
              </div>
              <div className="mt-10">
                <label for="familyName" className="text-gray-700 mt-10">
                  Презиме
                  <span className="text-red required-dot">*</span>
                </label>
                <label className="text-gray-700" for="lastName">
                  <Field
                    name="familyName"
                    placeholder="Внесете текст"
                    type="text"
                    className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                  ></Field>
                </label>
              </div>
              <div className="mt-10">
                <label for="email" className="text-gray-700">
                  Е-маил адреса
                  <span className="text-red required-dot">*</span>
                </label>
                <Field
                  name="email"
                  placeholder="Внесете е-маил адреса"
                  type="text"
                  className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                ></Field>
              </div>
              <div className="mt-10">
                <label for="password" className="text-gray-700">
                  Лозинка
                  <span className="text-red required-dot">*</span>
                </label>
                <Field
                  name="password"
                  placeholder="Внесете лозинка"
                  type="password"
                  className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                ></Field>
              </div>
              <div className="mt-10">
                <label for="confirmPassword" className="text-gray-700">
                  Повторете лозинка
                  <span className="text-red required-dot">*</span>
                </label>
                <Field
                  name="confirmPassword"
                  placeholder="Внесете ja лозинката уште еднаш"
                  type="password"
                  className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                ></Field>
              </div>
              <div className="w-1/3 mt-16 mb-20 block mx-auto m-0 text-center">
                <button
                  type="submit"
                  className="py-2 px-4 flex justify-center items-center bg-green-600 hover:bg-green-700 focus:green-green-500 focus:ring-offset-green-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg "
                >
                  Зачувај
                </button>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  userDetails: getUserDetails(state),
});

const mapDispatchToProps = {
  createUser,
  fetchUser,
  updateUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAdministrator);
