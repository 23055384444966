import _remove from "lodash/remove";
import * as InternalReportActions from "../actions/internalReport";
import reducerWithActionMap from "../utils/reducerWithActionsMap";

const initialState = {
  isAuthenticated: false,
  internalReportsList: {
    isFetching: false,
    isFetched: false,
    listData: [],
    total: 0,
  },
  internalReportDetails: {
    isFetching: false,
    isFetched: false,
    reportData: null,
  },
};

const setIssueListFetching = (fetching) => (state) => ({
  ...state,
  internalReportsList: {
    ...state.internalReportsList,
    isFetching: fetching,
  },
});

const setIssueList = (state, { payload: { listData, total } }) => ({
  ...state,
  internalReportsList: {
    ...state.internalReportsList,
    listData,
    total,
    isFetching: false,
    isFetched: true,
  },
});

const setIssueFetching = (fetching) => (state) => ({
  ...state,
  issueDetails: {
    ...state.issueDetails,
    isFetching: fetching,
  },
});

const setIssue = (state, { payload: { reportData } }) => ({
  ...state,
  internalReportDetails: {
    ...state.internalReportDetails,
    reportData,
    isFetching: false,
    isFetched: true,
  },
});

const setIssueDeleted = (state, { payload: { reportId } }) => {
  const newListData = state.internalReportsList.listData;
  _remove(newListData, { id: reportId });

  const newState = {
    ...state,
    internalReportsList: {
      ...state.internalReportsList,
      listData: newListData,
    },
  };
  return newState;
};

const actionMap = {
  [InternalReportActions.LIST_INTERNAL_REPORT_REQUEST]:
    setIssueListFetching(true),
  [InternalReportActions.LIST_INTERNAL_REPORT_SUCCESS]: setIssueList,
  [InternalReportActions.LIST_INTERNAL_REPORT_FAILURE]:
    setIssueListFetching(false),
  [InternalReportActions.DELETE_INTERNAL_REPORT_SUCCESS]: setIssueDeleted,
  [InternalReportActions.FETCH_INTERNAL_REPORT_REQUEST]: setIssueFetching(true),
  [InternalReportActions.FETCH_INTERNAL_REPORT_SUCCESS]: setIssue,
  [InternalReportActions.FETCH_INTERNAL_REPORT_FAILURE]:
    setIssueFetching(false),
  [InternalReportActions.UPDATE_INTERNAL_REPORT_SUCCESS]: setIssue,
};

export default reducerWithActionMap(actionMap, initialState);
