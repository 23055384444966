import axios from 'axios';

const defaultConfig = {
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 50000,
  withCredentials: true,
};

var instance = null;

export const initializeAxios = config => {
  instance = axios.create(Object.assign(defaultConfig, config));
};

const getAxiosInstance = () => {
  if (!instance) {
    initializeAxios();
  }
  return instance;
};

const setAccessToken = (token) => {
  getAxiosInstance().defaults.headers.common['Authorization'] = token;
};

export default getAxiosInstance;
export {setAccessToken};
