import getAxiosInstance from '../config/http';

export const LIST_MUNICIPALITY_REQUEST = 'MUNICIPALITY@LIST_MUNICIPALITY_REQUEST';
export const LIST_MUNICIPALITY_SUCCESS = 'MUNICIPALITY@LIST_MUNICIPALITY_SUCCESS';
export const LIST_MUNICIPALITY_FAILURE = 'MUNICIPALITY@LIST_MUNICIPALITY_FAILURE';

export const listMunicipalities = (params) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_MUNICIPALITY_REQUEST,
  });
  try{
    const municipalityData = await http.get('/municipalities', {params});
    dispatch({
      type: LIST_MUNICIPALITY_SUCCESS,
      payload: {
        listData: municipalityData.data,
      },
    });
    return municipalityData.data;
  }catch(e){
    dispatch({
      type: LIST_MUNICIPALITY_FAILURE,
    });
  }
};
