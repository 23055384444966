import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import UserList from "../../components/Users/UserList";
import Paging from "../../components/Paging";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import add from "../../img/add.svg";
import Layout from "../../Layout";
import BgImage from "../../components/BgImage";

import { getUsers } from "../../selectors/user";
import { listUsers, deleteUser, countUsers } from "../../actions/user";

const UserListPage = ({ listUsers, deleteUser, usersList }) => {
  const { isFetching, isFetched, listData, total } = usersList;
  const {
    listData: usersListData,
    isFetching: userListFetching,
    isFetched: usersListFetched,
  } = usersList;

  const [pageParams, setPageParams] = useState({ _limit: 10, _start: 0 });
  useEffect(() => {
    listUsers({
      "role.type_ne": "admin",
      _sort: "updated_at:DESC",
      ...pageParams,
    });
    countUsers();
  }, [pageParams]);

  return (
    <Layout>
      <Header></Header>
      <BgImage></BgImage>
      <div className="container mx-auto">
        <h2 className="text-center text-2xl uppercase leading-tight mt-20 mb-20">
          Корисници
        </h2>
        <div className="text-right">
          <div className="flex justify-end"></div>
        </div>
        <div className="mt-8 flex flex-row items-start justify-start">
          <Paging total={total} setPageParams={setPageParams}></Paging>
        </div>
        <UserList users={usersListData} deleteUser={deleteUser} />
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  usersList: getUsers(state),
});

const mapDispatchToProps = {
  listUsers,
  deleteUser,
  countUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserListPage);
