import _remove from 'lodash/remove';
import * as CommentActions from '../actions/internalComment';
import reducerWithActionMap from "../utils/reducerWithActionsMap";

const initialState = {
  isAuthenticated: false,
  commentList: {
    isFetching: false,
    isFetched: false,
    listData: [],
    total: 0,
  },
  commentDetails: {
    isFetching: false,
    isFetched: false,
    commentData: null,
  },
};

const setCommentListFetching = fetching => state => ({
  ...state,
  commentList: {
    ...state.commentList,
    isFetching: fetching,
  },
});

const setCommentList = (state, { payload: { listData, total } }) => ({
  ...state,
  commentList: {
    ...state.commentList,
    listData,
    total,
    isFetching: false,
    isFetched: true,
  },
});

const setCommentFetching = fetching => state => ({
  ...state,
  commentDetails: {
    ...state.commentDetails,
    isFetching: fetching,
  },
});

const setComment = (state, { payload: { commentData } }) => ({
  ...state,
  commentDetails: {
    ...state.commentDetails,
    commentData,
    isFetching: false,
    isFetched: true,
  },
});

const setCommentDeleted = (state, { payload: { commentId } }) => {
  const newListData = state.commentList.listData;
  _remove(newListData, { id: commentId });

  const newState = {
    ...state,
    commentList: {
      ...state.commentList,
      listData: newListData,
    },
  };
  return newState;
};

const actionMap = {
  [CommentActions.LIST_INTERNAL_COMMENT_REQUEST]: setCommentListFetching(true),
  [CommentActions.LIST_INTERNAL_COMMENT_SUCCESS]: setCommentList,
  [CommentActions.LIST_INTERNAL_COMMENT_FAILURE]: setCommentListFetching(false),
  [CommentActions.DELETE_INTERNAL_COMMENT_SUCCESS]: setCommentDeleted,
  [CommentActions.FETCH_INTERNAL_COMMENT_REQUEST]: setCommentFetching(true),
  [CommentActions.FETCH_INTERNAL_COMMENT_SUCCESS]: setComment,
  [CommentActions.FETCH_INTERNAL_COMMENT_FAILURE]: setCommentFetching(false),
};

export default reducerWithActionMap(actionMap, initialState);


