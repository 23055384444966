import getAxiosInstance from '../config/http';

export const CREATE_INTERNAL_COMMENT_REQUEST = "INTERNAL_COMMENT@CREATE_INTERNAL_COMMENT_REQUEST";
export const CREATE_INTERNAL_COMMENT_SUCCESS = "INTERNAL_COMMENT@CREATE_INTERNAL_COMMENT_SUCCESS";
export const CREATE_INTERNAL_COMMENT_FAILURE = "INTERNAL_COMMENT@CREATE_INTERNAL_COMMENT_FAILURE";

export const createInternalComment = (file, commentData) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: CREATE_INTERNAL_COMMENT_REQUEST,
  });
  try {
    const formData = new FormData();
    formData.append("files.files", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    formData.append("data", JSON.stringify(commentData));
    const { data } = await http.post(
      '/internal-report-comments',
      formData
    );
    dispatch({
      type: CREATE_INTERNAL_COMMENT_SUCCESS,
      payload: {
        commentData: data,
      },
    });
    return data;
  } catch (e) {
    dispatch({
      type: CREATE_INTERNAL_COMMENT_FAILURE,
    });
    throw e;
  }
};

export const LIST_INTERNAL_COMMENT_REQUEST = 'INTERNAL_COMMENT@LIST_INTERNAL_COMMENT_REQUEST';
export const LIST_INTERNAL_COMMENT_SUCCESS = 'INTERNAL_COMMENT@LIST_INTERNAL_COMMENT_SUCCESS';
export const LIST_INTERNAL_COMMENT_FAILURE = 'INTERNAL_COMMENT@LIST_INTERNAL_COMMENT_FAILURE';

export const listInternalComments = (params) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_INTERNAL_COMMENT_REQUEST,
  });
  try{
    const commentData = await http.get('/internal-report-comments', {params});
    dispatch({
      type: LIST_INTERNAL_COMMENT_SUCCESS,
      payload: {
        listData: commentData.data,
      },
    });
  }catch(e){
    dispatch({
      type: LIST_INTERNAL_COMMENT_FAILURE,
    });
  }
};

export const FETCH_INTERNAL_COMMENT_REQUEST = 'INTERNAL_COMMENT@FETCH_INTERNAL_COMMENT_REQUEST';
export const FETCH_INTERNAL_COMMENT_SUCCESS = 'INTERNAL_COMMENT@FETCH_INTERNAL_COMMNENT_SUCCESS';
export const FETCH_INTERNAL_COMMENT_FAILURE = 'INTERNAL_COMMENT@FETCH_INTERNAL_COMMENT_FAILURE';

export const fetchInternalComment = commentId => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: FETCH_INTERNAL_COMMENT_REQUEST,
  });
  try{
    const commentData = await http.get(`/internal-report-comments/${commentId}`);
    dispatch({
      type: FETCH_INTERNAL_COMMENT_SUCCESS,
      payload: {
        commentData: commentData.data,
      }
    });
  } catch(e){
    dispatch({
      type: FETCH_INTERNAL_COMMENT_FAILURE,
    });
  };
};

export const UPDATE_INTERNAL_COMMENT_REQUEST = 'INTERNAL_COMMENT@UPDATE_INTERNAL_COMMENT_REQUEST';
export const UPDATE_INTERNAL_COMMENT_SUCCESS = 'INTERNAL_COMMENT@UPDATE_INTERNAL_COMMENT_SUCCESS';
export const UPDATE_INTERNAL_COMMENT_FAILURE = 'INTERNAL_COMMENT@UPDATE_INTERNAL_COMMENT_FAILURE';

export const updateInternalComment = (id, commentData) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: UPDATE_INTERNAL_COMMENT_REQUEST,
  });
  try{
    const data = await http.put(`/internal-report-comments/${id}`, commentData);
    dispatch({
      type: UPDATE_INTERNAL_COMMENT_SUCCESS,
      payload: {
        commentData: data,
      }
    });
  } catch(e){
    dispatch({
      type: UPDATE_INTERNAL_COMMENT_FAILURE,
    });
  };
};

export const DELETE_INTERNAL_COMMENT_REQUEST = 'INTERNAL_COMMENT@DELETE_INTERNAL_COMMENT_REQUEST';
export const DELETE_INTERNAL_COMMENT_SUCCESS = 'INTERNAL_COMMENT@DELETE_INTERNAL_COMMENT_SUCCESS';
export const DELETE_INTERNAL_COMMENT_FAILURE = 'INTERNAL_COMMENT@DELETE_INTERNAL_COMMENT_FAILURE';

export const deleteInternalComment = id => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: DELETE_INTERNAL_COMMENT_REQUEST,
  });
  try{
    const data = await http.delete(`/internal-report-comments/${id}`);
    dispatch({
      type: DELETE_INTERNAL_COMMENT_SUCCESS,
      payload: {
        commentId:id,
      }
    });
    return data;
  } catch(e){
    dispatch({
      type: DELETE_INTERNAL_COMMENT_FAILURE,
    });
  };
};
