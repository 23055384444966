import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import logo from "../img/logo.png";
import DTLogo from "../img/dti_logo.png";
import { getProfile, logout } from "../actions/user";
import { getUserDetails } from "../selectors/user";

const HeaderDTI = ({ getProfile, getUserDetails, userDetails, logout }) => {
  const [filtersOpen, setFiltersOpen] = useState(false);
  const {
    userData,
    isFetching: userDataIsFetching,
    isFetched: userDataIsFetched,
  } = userDetails;

  const history = useHistory();

  useEffect(() => {
    getProfile();
  }, []);

  const toggleFiltersOpen = () => {
    setFiltersOpen(!filtersOpen);
  };

  let roleType = "";
  if (userDetails.isFetched && userDetails.userData) {
    roleType = userDetails.userData.role.type;
  }

  const signoutDTI = async () => {
    try {
      await logout();
      history.push("/dit");
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="container-fluid mx-auto printHide bg-dti">
      <nav className="bg-dti dark:bg-gray-800 shadow">
        <div className="max-w-7xl mx-auto px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <img className="" src={DTLogo} alt="Logo DTI" />
              <div className="ml-10 flex items-baseline space-x-4">
                <Link to="/internalReports">
                  <div className="text-white hover:text-darkGray px-3 py-2 rounded-md text-sm font-medium">
                    Листа на пријави
                  </div>
                </Link>
                <button
                  type="submit"
                  onClick={signoutDTI}
                  className="text-white hover:text-darkGray px-3 py-2 rounded-md text-sm font-medium"
                >
                  Одјави се
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userDetails: getUserDetails(state),
});

const mapDispatchToProps = {
  getProfile,
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderDTI);
