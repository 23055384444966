import { combineReducers } from "redux";

import issue from "./issue";
import advice from "./advice";
import user from "./user";
import post from "./post";
import comment from "./comment";
import industry from "./industry";
import internalReport from "./internalReport";
import internalComment from "./internalComment";
import dtiLocation from "./dtiLocation";

const rootReducer = combineReducers({
  issue,
  advice,
  user,
  post,
  comment,
  industry,
  internalReport,
  internalComment,
  dtiLocation,
});

export default rootReducer;
