import React, { useEffect } from "react";
import { Formik, Form, Field } from "formik";
import Header from "../../components/Header";
import { connect } from "react-redux";
import { getInternalReports } from "../../selectors/internalReport";
import { getUserDetails } from "../../selectors/user";
import {
  listInternalReports,
  deleteInternalReport,
} from "../../actions/internalReport";
import Layout from "../../Layout";
import InternalReportList from "../../components/InternalReports/InternalReportList";

const InternalReportsListPage = ({
  listInternalReports,
  deleteInternalReport,
  internalReportsList,
  getUserDetails,
  userDetails,
}) => {
  const { isFetching, isFetched, listData } = internalReportsList;
  const {
    listData: internalReportsListData,
    isFetching: internalReportsListFetching,
    isFetched: internalReportsListFetched,
  } = internalReportsList;

  useEffect(() => {
    if (userDetails.isFetched && userDetails.userData) {
      let roleType = "";
      roleType = userDetails.userData.role.type;
      const filter = { _sort: "updated_at:DESC" };
      if (roleType === "subinspector") {
        filter["assigned_subinspector.id"] = userDetails.userData.id;
      } else if (roleType === "regional_inspector") {
        filter["assigned_regional_inspector.id"] = userDetails.userData.id;
      }
      listInternalReports(filter);
    }
  }, [userDetails]);

  return (
    <Layout>
      <Header></Header>
      <div className="container mx-auto">
        <div className="text-center text-2xl leading-tight mt-20 mb-20 uppercase">
          Листа на пријави
        </div>
        <InternalReportList
          internalReports={internalReportsList.listData}
          deleteIssue={deleteInternalReport}
        />
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  internalReportsList: getInternalReports(state),
  userDetails: getUserDetails(state),
});

const mapDispatchToProps = {
  listInternalReports,
  deleteInternalReport,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InternalReportsListPage);
