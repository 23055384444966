import getAxiosInstance from '../config/http';

export const LIST_INDUSTRY_REQUEST = 'INDUSTRY@LIST_INDUSTRY_REQUEST';
export const LIST_INDUSTRY_SUCCESS = 'INDUSTRY@LIST_INDUSTRY_SUCCESS';
export const LIST_INDUSTRY_FAILURE = 'INDUSTRY@LIST_INDUSTRY_FAILURE';

export const listIndustries = (params) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_INDUSTRY_REQUEST,
  });
  try{
    const industryData = await http.get('/industries', {params});
    dispatch({
      type: LIST_INDUSTRY_SUCCESS,
      payload: {
        listData: industryData.data,
      },
    });
    return industryData.data;
  }catch(e){
    dispatch({
      type: LIST_INDUSTRY_FAILURE,
    });
  }
};

export const COUNT_INDUSTRY_REQUEST = 'INDUSTRY@COUNT_INDUSTRY_REQUEST';
export const COUNT_INDUSTRY_SUCCESS = 'INDUSTRY@COUNT_INDUSTRY_SUCCESS';
export const COUNT_INDUSTRY_FAILURE = 'INDUSTRY@COUNT_INDUSTRY_FAILURE';

export const countIndustries = (params) => async dispatch => {
  const http = getAxiosInstance();
  dispatch({
    type: COUNT_INDUSTRY_REQUEST,
  });
  try{
    const industryData = await http.get('/industries/count',{params});
    dispatch({
      type: COUNT_INDUSTRY_SUCCESS,
      payload: {
        listData: industryData.data,
      },
    });
    return industryData.data;
  }catch(e){
    dispatch({
      type: COUNT_INDUSTRY_FAILURE,
    });
  }
};
