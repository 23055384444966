import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import avatar from "../../img/nophoto.jpg";

const PostList = ({ posts, deletePost, ...props }) => {
  return (
    <>
      <div className="container mx-auto">
        <div className="py-8">
          <div className="shadow rounded-lg">
            <table className="min-w-full leading-normal">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                  >
                    Редни бр.
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                  >
                    Наслов
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                  >
                    Поднаслов
                  </th>
                  <th
                    scope="col"
                    className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                  >
                    Кратки исечак из текста
                  </th>
                  <th
                    scope="col-span-2"
                    className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                  >
                    Фотографија
                  </th>
                  <th
                    scope="col-span"
                    className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                  >
                    Линк
                  </th>
                  <th
                    scope="col-span"
                    className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                  >
                    Обновљено
                  </th>
                  <th
                    scope="col-span-2"
                    className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                  >
                    Погледај детаље
                  </th>
                  <th
                    scope="col-span-2"
                    className="px-5 py-3 bg-white border-b border-t border-gray-200 text-black text-left text-sm uppercase font-bold"
                  ></th>
                </tr>
              </thead>
              <tbody>
                {posts.map((item, idx) => {
                  let substring = item.content.substring(0, 100);
                  return (
                    <tr {...props}>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {idx + 1}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {item.title}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {item.subtitle}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {substring + "..."}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          <img
                            src={
                              item.featuredImage === null
                                ? avatar
                                : `${process.env.REACT_APP_API_URL}${item.featuredImage.formats.thumbnail.url}`
                            }
                          />
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-blue whitespace-no-wrap">
                          <a href={item.link} target="_blank">
                            {item.link}
                          </a>
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p className="text-gray-900 whitespace-no-wrap">
                          {moment(item.updated_at).format("DD.MM.YYYY")}
                        </p>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <Link
                          to={`/editPost/${item.id}`}
                          className="text-blue"
                        >
                          Уреди нову вест
                        </Link>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <button
                          className="text-red hover:text-red focus:outline-none"
                          onClick={() => deletePost(item.id)}
                        >
                          Обриши нову вест
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default PostList;
