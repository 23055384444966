const reducerWithActionMap = (actionMap, initialState) => {
  if (!initialState) {
    throw new Error('Initial state cannot be null!');
  }

  return (state = initialState, action) => {
    const reducer = actionMap[action.type];

    return reducer ? reducer(state, action) : state;
  };
};

export default reducerWithActionMap;
