import React from "react";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="container mx-auto">
        <h1>Politika privatnosti</h1>
        <br />
        <p>Stupa na snagu: 01.januara 2022 година.</p>
        <br />
        <p>
          Savez samostalnih sindikata Srbije ( „nam “, „mi “ ili „naše “)
          upravlja mobilnom aplikacijom SSSS - Zaštita radnih prava („Usluga “).
        </p>
        <br />
        <p>
          Na ovoj strani ćete naći informacije o prikupljanju, upotrebi i
          otkrivanju ličnih podataka kada koristite naše usluge, kao i
          informacije o tome koje izbore treba povezati sa tim podacima.
        </p>
        <br />
        <p>
          Vaše podatke koristimo da bismo obezbedili i poboljšali Uslugu.
          Korišćenjem Usluge, vi pristajete na to da informacije budu
          prikupljene i upotrebljene u saglasnosti sa ovom politikom. Osim ako
          je drugačije utvrđeno datom Politikom privatnosti, pojmovi koji se
          koriste u ovoj Politici privatnosti imaju isto značenje kao i u našim
          Uslovima o korišćenju.
        </p>
        <br />
        <h1>Zašto aplikacija koristi lične podatke? Korisnički nalog </h1>
        <br />
        <p>
          Da biste mogli da sinhronizujete svoje podatke na nekoliko uređaja i
          da koristite usluge aplikacije SSSS - Zaštita radnih prava, neophodno
          je da kreirate nalog.
        </p>
        <br />
        <p>
          Podaci o nalogu su klasifikovani kao lični samo ukoliko se mogu
          koristiti da bi vas identifikovali.
        </p>
        <br />
        <p>Postoje dva načina za kreiranje korisničkog naloga: </p>
        <ul>
          <li>- Unošenjem vase adrese za e-poštu i kreiranjem lozinke,</li>
          <li>
            - ili korišćenjem detalja za najavljivanje na Google, Facebook ili
            Apple ID.
          </li>
        </ul>
        <p>
          Аko odaberete da kreirate nalog na Aplikaciji SSSS - Zaštita radnih
          prava uz pomoć e-pošte, aplikacija će koristiti e-poštu koja je data
          da bi se identifikovao vaš korisnički nalog.
        </p>
        <br />
        <p>
          Ako odaberete da kreirate nalog za Aplikaciju SSSS - Zaštita radnih
          prava korišćenjem detalja za najavljivanje na Google-u, Facebook-u ili
          Apple ID-ju, аplikacija će uputiti zahtev za povezivanje sa vašim
          nalogom uz zahtev da poveže aplikaciju SSSS - Zaštita radnih prava i
          prikupiće e-poštu, ime i fotografiju povezanog naloga. U tom slučaju,
          Google, Facebook ili Apple mogu prikupiti podatke u vezi sa
          povezanošću sa vašim korisničkim nalogom na Aplikaciju SSSS - Zaštita
          radnih prava. Veza sa korisničkim nalogom aplikacije SSSS - Zaštita
          radnih prava ostaće aktivna 30 dana. Preporučujemo da prvo pročitate
          politiku o privatnosti Google-a, Facebook-a ili Apple-a.
        </p>
        <br />
        <p>
          Aplikacija koristi ove podatke jedino da bi vas mogla identifikovati
          kada se najavite na aplikaciju. Vaši podaci neće biti predate trećim
          stranama pod nikakvim okolnostima.
        </p>
        <br />
        <h1>Prikuplajne i upotreba informacija</h1>
        <br />
        <p>
          Mi prikupljamo nekoliko različitih vrsta informacija za različite
          svrhe da bismo obezbedili i poboljšali našu Uslugu. Vrste podataka
          koji se prikupljaju:
        </p>
        <br />
        <p>Lični podaci</p>
        <br />
        <p>
          Kada koristite našu Uslugu, možemo zatražiti od vas neke lično
          prepoznatljive informacije koje se mogu koristiti za kontakt sa vama
          ili za vašu identifikaciju („lični podaci “). Lične prepoznatljive
          informacije mogu obuhvatiti, ali se ne ograničavaju samo na:
        </p>
        <br />
        <ul>
          <li>- e-poštu</li>
          <li>- Ime i prezime</li>
          <li>- Pol i uzrast</li>
          <li>- Теlefonski broj</li>
        </ul>
        <p>Podaci o korišćenju</p>
        <br />
        <p>
          Kada pristupate Usluzi putem vašeg mobilnog uređaja, mi moramo
          automatski pristupiti i prikupiti određene informacije, uključujući i,
          ali se ne ograničavajuti na tip mobilnog uređaja koji koristite,
          jedinstveni ID broj vašeg mobilnog uređaja, IP adresu vašeg mobilnog
          uređaja, operativni sistem vašeg mobilnog uređaja, galeriju sa
          fotografijama, tip pretraživača koji koristite, jedinstvene
          identifikatore uređaja i druge dijagnostičke podatke („podaci o
          korišćenju“).
        </p>
        <br />
        <h1>Korišćenje podataka</h1>
        <br />
        <p>
          Savez samostalnih sindikata Srbije koristi prikupljene podatke u razne
          svrhe:
        </p>
        <br />
        <ul>
          <li>- za obezbeđivanje i održavanje Usluge </li>
          <li>
            - za prijavljivanje kršenja radničkih prava nadležnim institucijama
          </li>
          <li>- da bi vas obavestili o promeni u našim Uslugama </li>
          <li>
            - da bi vam omogućili da učestvujete u interaktivnim opcijama Usluge
            kada vi to poželite
          </li>
          <li>- da bi obezbedili brigu i podršku o klijentima</li>
          <li>
            - da bi obezbedili analizu i vredne informacije da bismo mogli da
            poboljšamo Uslugu
          </li>
          <li>- za praćenje korišćenja Usluge </li>
          <li>- za otkrivanje, sprečavanje i rešavane tehničkih pitanja</li>
        </ul>
        <br />
        <p>Prenos podataka</p>
        <br />
        <p>
          Vaše informacije, uključujući i lične podatke, mogu biti prenesene – i
          čuvane – na kompjuterima koji se nalaze van vaše države, regiona ili
          sudske nadležnosti, gde se zakoni o zaštiti podataka mogu razlikovati
          od onih u vašoj sudskoj nadležnosti. Ukoliko se nalazite van Srbije i
          odlučite da nam date informacije, treba da znate da prenosimo podatke,
          uključujući i lične podatke, u Srbiju i tamo ih obrađujemo.
        </p>
        <br />
        <p>
          Vaša saglasnost za Politiku privatnosti zajedno sa dostavljenim
          informacijama znači da pristajete na takav prenos.
        </p>
        <br />
        <p>
          Savez samostalnih sindikata Srbije će preuzeti sve potrebne razumne
          korake da bi bili sigurni da su vaši podaci bezbedni u da su u skladu
          sa Politikom privatnosti i vaši lični podaci se neće prenositi u
          organizaciju ili državu koja nema odgovarajuću kontrolu, uključujući i
          bezbednost vaših podataka i drugih ličnih informacija.
        </p>
        <br />
        <h1>Obelodanjivanje podataka</h1>
        <br />
        <h1>Zakonski uslovi</h1>
        <br />
        <p>
          Savez samostalnih sindikata Srbije može učiniti dostupnim vaše lične
          podatke u slučaju kada veruje da je to neophodno za:
        </p>
        <br />
        <ul>
          <li>- postupanje u skladu sa zakonskim obavezama </li>
          <li>
            - zaštitu i odbranu prava ili vlasništva Savez samostalnih sindikata
            Srbije
          </li>
          <li>
            - za sprečavanje ili ispitivanje mogućeg nezakonitog dela povezanog
            sa uslugom
          </li>
          <li>- za zaštitu lične bezbednosti korisnika usluge ili javnosti</li>
          <li>- za zaštitu od zakonske odgovornosti </li>
        </ul>
        <h1>Bezbednost podataka</h1>
        <br />
        <p>
          Bezbednost vaših podataka nam je veoma važna, ali morate imati na umu
          da niti jedan način prenosa putem interneta ili način elektronskog
          čuvanja nije 100% bezbedan. Uprkos naporima da koristimo komercijalno
          prihvatljiva sredstva za zaštitu vaših ličnih podataka, nismo u
          mogućnosti da garantujemo apsolutnu bezbednost.
        </p>
        <br />
        <h1>Pružatelj usluga</h1>
        <br />
        <p>
          Možemo angažovati treću stranu, društvo ili pojedince da bismo vam
          okakšali vašu Uslugu („Pružatelj usluga“), koji obezbeđuju Uslugu u
          naše ime, vrše usluge povezane sa našom Uslugom ili nam pomažu oko
          analiziranja načina na koji se koristi naša Usluga. Ove treće strane
          imaju pristup vašim ličnim podacima samo da bi obavili zadatak u vaše
          ime i u obavezi su da ne čine dostupnim ili da ne upotrebljavaju
          podatke u druge svrhe.
        </p>
        <br />
        <h1>Analitika</h1>
        <br />
        <p>
          Aplikacija ne koristi statističke podatke za obradu ličnih podataka
        </p>
        <br />
        <h1>Linkovi ka drugim stranama</h1>
        <br />
        <p>
          Naša Usluga može da sadrži linkove ka drugim stranama kojima ne
          upravljamo mi. Kada kliknete na link treće strane, bićete preusmereni
          ka toj trećoj strani. Savetujemo vam da pogledate Politiku o
          privatnosti svake od strana koju posećujete. Mi nemamo kontrolu i ne
          preuzimamo odgovornost za sadržinu, politike ili prakse privatnosti
          strana ili usluga trećih lica.
        </p>
        <br />
        <h1>Privatnost dece</h1>
        <br />
        <p>
          Naša usluga se ne odnosi na osobe mlađe od 18 godina („Deca“). Mi ne
          prikupljamo svesno podatke o ličnim prepoznatljivim informacijama
          osoba mlađih od 18 godina. Ukoliko ste roditelj ili staratelj i znate
          da nam je vaše dete dalo lične podatke, kontaktirajte nas. Ukoliko
          dođemo do saznanja da smo prikupili lične podatke dece bez potvrde
          roditeljske saglasnosti, preuzećemo korake da odstranimo te podatke sa
          naših servera.
        </p>
        <br />
        <h1>Promena ove Politike privatnosti</h1>
        <br />
        <p>
          S vremena na vreme, možemo ažurirati našu Politiku privatnosti.
          Obavestićemo vas o ovim izmenama objavljivanjem nove Politike
          privatnosti na ovoj strani. Obavestićemo vas putem е-pošte i/ili
          saopštenja o našoj Usluzi pre nego što te promene stupe na snagu i
          ažuriraćemo „datum stupanja na snagu“ na početku ove Politike
          privatnosti.
        </p>
        <br />
        <p>
          Savetujemo vam da povremeno proveravate Politiku privatnosti o mogućim
          izmenama. Promene u Politici privatnosti su važeće od trenutka
          objavljivanja na ovoj strani.
        </p>
        <br />
        <h1>Kontaktirajte nas</h1>
        <br />
        <p>
          Ukoliko imate pitanja u vezi sa ovom Politikom privatnosti, možete nas
          kontaktirati putem е-pošte: pravna@sindikat.rs
        </p>
        <p>
          Aplikacija SSSS - Zaštita radnih prava je podržana od strane
          Međunarodne organizacije rada.
        </p>
        <br />
      </div>
    </>
  );
};
export default PrivacyPolicy;
