import getAxiosInstance from "../config/http";

export const LIST_LOCATIONS_REQUEST = "DTI_LOCATION@LIST_LOCATIONS_REQUEST";
export const LIST_LOCATIONS_SUCCESS = "DTI_LOCATION@LIST_LOCATIONS_SUCCESS";
export const LIST_LOCATIONS_FAILURE = "DTI_LOCATION@LIST_LOCATIONS_FAILURE";

export const listDtiLocations = (params) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_LOCATIONS_REQUEST,
  });
  try {
    const categoryData = await http.get("/dti-locations", { params });
    dispatch({
      type: LIST_LOCATIONS_SUCCESS,
      payload: {
        listData: categoryData.data,
      },
    });
    return categoryData.data;
  } catch (e) {
    dispatch({
      type: LIST_LOCATIONS_FAILURE,
    });
  }
};
