import getAxiosInstance from "../config/http";

export const CREATE_INTERNAL_REPORT_REQUEST =
  "INTERNAL_REPORT@CREATE_INTERNAL_REPORT_REQUEST";
export const CREATE_INTERNAL_REPORT_SUCCESS =
  "INTERNAL_REPORT@CREATE_INTERNAL_REPORT_SUCCESS";
export const CREATE_INTERNAL_REPORT_FAILURE =
  "INTERNAL_REPORT@CREATE_INTERNAL_REPORT_FAILURE";

export const createInternalReport = (reportData) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: CREATE_INTERNAL_REPORT_REQUEST,
  });
  try {
    const { data } = await http.post("/internal-reports", reportData);
    dispatch({
      type: CREATE_INTERNAL_REPORT_SUCCESS,
      payload: {
        reportData: data,
      },
    });
    return data;
  } catch (e) {
    dispatch({
      type: CREATE_INTERNAL_REPORT_FAILURE,
    });
    throw e;
  }
};

export const LIST_INTERNAL_REPORT_REQUEST =
  "INTERNAL_REPORT@LIST_INTERNAL_REPORT_REQUEST";
export const LIST_INTERNAL_REPORT_SUCCESS =
  "INTERNAL_REPORT@LIST_INTERNAL_REPORT_SUCCESS";
export const LIST_INTERNAL_REPORT_FAILURE =
  "INTERNAL_REPORT@LIST_INTERNAL_REPORT_FAILURE";

export const listInternalReports = (params) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: LIST_INTERNAL_REPORT_REQUEST,
  });
  try {
    const reportData = await http.get("/internal-reports", { params });
    dispatch({
      type: LIST_INTERNAL_REPORT_SUCCESS,
      payload: {
        listData: reportData.data,
      },
    });
  } catch (e) {
    dispatch({
      type: LIST_INTERNAL_REPORT_FAILURE,
    });
  }
};

export const COUNT_INTERNAL_REPORT_REQUEST =
  "INTERNAL_REPORT@COUNT_INTERNAL_REPORT_REQUEST";
export const COUNT_INTERNAL_REPORT_SUCCESS =
  "INTERNAL_REPORT@COUNT_INTERNAL_REPORT_SUCCESS";
export const COUNT_INTERNAL_REPORT_FAILURE =
  "INTERNAL_REPORT@COUNT_INTERNAL_REPORT_FAILURE";

export const countInternalReports = (params) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: COUNT_INTERNAL_REPORT_REQUEST,
  });
  try {
    const reportData = await http.get("/internal-reports/count", { params });
    dispatch({
      type: COUNT_INTERNAL_REPORT_SUCCESS,
      payload: {
        listData: reportData.data,
      },
    });
    return reportData.data;
  } catch (e) {
    dispatch({
      type: COUNT_INTERNAL_REPORT_FAILURE,
    });
  }
};

export const FETCH_INTERNAL_REPORT_REQUEST =
  "INTERNAL_REPORT@FETCH_INTERNAL_REPORT_REQUEST";
export const FETCH_INTERNAL_REPORT_SUCCESS =
  "INTERNAL_REPORT@FETCH_INTERNAL_REPORT_SUCCESS";
export const FETCH_INTERNAL_REPORT_FAILURE =
  "INTERNAL_REPORT@FETCH_INTERNAL_REPORT_FAILURE";

export const fetchInternalReport = (reportId) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: FETCH_INTERNAL_REPORT_REQUEST,
  });
  try {
    const reportData = await http.get(`/internal-reports/${reportId}`);
    dispatch({
      type: FETCH_INTERNAL_REPORT_SUCCESS,
      payload: {
        reportData: reportData.data,
      },
    });
    return reportData.data;
  } catch (e) {
    dispatch({
      type: FETCH_INTERNAL_REPORT_FAILURE,
    });
  }
};

export const UPDATE_INTERNAL_REPORT_REQUEST =
  "INTERNAL_REPORT@UPDATE_INTERNAL_REPORT_REQUEST";
export const UPDATE_INTERNAL_REPORT_SUCCESS =
  "INTERNAL_REPORT@UPDATE_INTERNAL_REPORT_SUCCESS";
export const UPDATE_INTERNAL_REPORT_FAILURE =
  "INTERNAL_REPORT@UPDATE_INTERNAL_REPORT_FAILURE";

export const updateInternalReport = (id, updatedData) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: UPDATE_INTERNAL_REPORT_REQUEST,
  });
  try {
    const reportData = await http.put(`/internal-reports/${id}`, updatedData);
    dispatch({
      type: UPDATE_INTERNAL_REPORT_SUCCESS,
      payload: {
        reportData: reportData.data,
      },
    });
  } catch (e) {
    dispatch({
      type: UPDATE_INTERNAL_REPORT_FAILURE,
    });
  }
};

export const DELETE_INTERNAL_REPORT_REQUEST =
  "INTERNAL_REPORT@DELETE_INTERNAL_REPORT_REQUEST";
export const DELETE_INTERNAL_REPORT_SUCCESS =
  "INTERNAL_REPORT@DELETE_INTERNAL_REPORT_SUCCESS";
export const DELETE_INTERNAL_REPORT_FAILURE =
  "INTERNAL_REPORT@DELETE_INTERNAL_REPORT_FAILURE";

export const deleteInternalReport = (id) => async (dispatch) => {
  const http = getAxiosInstance();
  dispatch({
    type: DELETE_INTERNAL_REPORT_REQUEST,
  });
  try {
    const data = await http.delete(`/internal-reports/${id}`);
    dispatch({
      type: DELETE_INTERNAL_REPORT_SUCCESS,
      payload: {
        reportId: id,
      },
    });
    return data;
  } catch (e) {
    dispatch({
      type: DELETE_INTERNAL_REPORT_FAILURE,
    });
  }
};
