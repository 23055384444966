import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import AdviceList from "../../components/Advice/AdviceList";
import Paging from "../../components/Paging";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import add from "../../img/add.svg";
import Layout from "../../Layout";
import BgImage from "../../components/BgImage";

import { getAdvice } from "../../selectors/advice";
import { listAdvice, deleteAdvice, countAdvice } from "../../actions/advice";

const AdviceListPage = ({
  listAdvice,
  updateAdvice,
  deleteAdvice,
  adviceList,
}) => {
  const { isFetching, isFetched, listData, total } = adviceList;
  const {
    listData: adviceListData,
    isFetching: adviceListFetching,
    isFetched: adviceListFetched,
  } = adviceList;

  const [pageParams, setPageParams] = useState({ _limit: 10, _start: 0 });
  useEffect(() => {
    listAdvice({ _sort: "updated_at:DESC", ...pageParams });
    countAdvice();
  }, [pageParams]);

  return (
    <Layout>
      <Header></Header>
      <BgImage></BgImage>
      <div className="container mx-auto">
        <h2 className="text-center text-2xl uppercase leading-tight mt-10 mb-10">
          Сaвети
        </h2>
        <div className="text-right">
          <div className="flex justify-end">
            <Link
              to="/createAdvice"
              className="flex flex-row align-items align-middle items-center justify-end"
            >
              <div className="mr-4 text-blue">ДОДАЈ САВЕТ</div>
              <img
                src={add}
                className="rounded-full bg-blue w-10 h-10 p-3"
                alt="Add button"
              />
            </Link>
          </div>
        </div>
        <div className="mt-8 flex flex-row items-start justify-start">
          <Paging total={total} setPageParams={setPageParams}></Paging>
        </div>
        <AdviceList advice={adviceListData} deleteAdvice={deleteAdvice} />
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  adviceList: getAdvice(state),
});

const mapDispatchToProps = {
  listAdvice,
  deleteAdvice,
  countAdvice
};

export default connect(mapStateToProps, mapDispatchToProps)(AdviceListPage);
