import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Link, useParams, useHistory } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { fetchIssue, updateIssue } from "../../actions/issue";
import { createInternalReport } from "../../actions/internalReport";
import { getIssueDetails } from "../../selectors/issue";
import CommentList from "../../components/Comments/CommentList";
import {
  getCommentDetails,
  getComments,
  getPredefinedComments,
} from "../../selectors/comment";
import {
  listPredefinedComments,
  listComments,
  createComment,
  updateComment,
  deleteComment,
} from "../../actions/comment";
import BgImage from "../../components/BgImage";
import Header from "../../components/Header";
import Layout from "../../Layout";
import avatar from "../../img/nophoto.jpg";

const startValues = {
  status: "",
};

const internalReportValues = {
  comment: "",
};

const commentStartValues = {
  content: "",
  link: "",
};

const IssueDetails = ({
  fetchIssue,
  issueDetails,
  updateIssue,
  listPredefinedComments,
  listComments,
  createComment,
  fetchComment,
  updateComment,
  deleteComment,
  createInternalReport,
  predefinedCommentList,
  commentList,
}) => {
  let { id } = useParams();
  const history = useHistory();
  const [initialValues, setInitialValues] = useState(startValues);
  const [commentInitialValues, setCommentInitialValues] = useState({
    ...commentStartValues,
    report: id,
  });
  const [internalReportInitialValues, setInternalReportInitialValues] =
    useState(internalReportValues);

  const { isFetching, isFetched, listData } = predefinedCommentList;
  // const { isFetching, isFetched, listData } = commentList;

  const {
    listData: predefinedCommentListData,
    isFetching: predefinedCommentListFetching,
    isFetched: predefinedCommentListFetched,
  } = predefinedCommentList;

  const {
    listData: commentListData,
    isFetching: commentListFetching,
    isFetched: commentListFetched,
  } = commentList;

  const predefinedComments = predefinedCommentList.listData;
  const comments = commentList.listData;

  const [file, setFile] = useState(null);

  const handleAttachment = async ({ target }) => {
    if (!target.files.length) {
      return false;
    }
    setFile(target.files[0]);
  };

  useEffect(() => {
    listComments({
      report: id,
    });
    listPredefinedComments();
    fetchIssue(id);
  }, []);

  // let reportType = "";
  // if (issueDetails.issueData) {
  //   let type = issueDetails.issueData.reportType;
  //   if (type === "request") {
  //     reportType = "Захтев";
  //   } else if (type === "complaint") {
  //     reportType = "Жалба";
  //   } else {
  //     reportType = "Прекршај";
  //   }
  // }

  let reportStatus = "";
  if (issueDetails.issueData) {
    let status = issueDetails.issueData.status;
    if (status === "reported") {
      reportStatus = "Отворена";
    } else if ((status = "in_progress")) {
      reportStatus = "Поступак у току";
    } else {
      reportStatus = "Решена";
    }
  }

  const print = () => {
    window.print();
  };

  const handleChangeStatus = (e) => {
    updateIssue(id, { status: e.target.value });
    // alert("Статусот на пријавата е променет");
    // history.push("/issues");
  };

  const formSubmit = async (values) => {
    let updatedStatus = null;
    try {
      updatedStatus = await updateIssue(id, {
        ...issueDetails.issueData,
        ...values,
      });
      history.push("/issues");
    } catch (e) {
      console.log(e);
    }
  };

  const chooseComment = (setFieldValue, e) => {
    setFieldValue("content", e.target.value);
  };

  const chooseInternalReportType = (setFieldValue, e) => {
    console.log("internal rep type");
    console.log(e.target.value);
    setFieldValue("report_basis", e.target.value);
  };

  const applyComment = async (values) => {
    let comment = null;
    try {
      comment = await createComment(file, values);
      listComments({ report: id });
    } catch (e) {
      console.log(e);
      // alert("Something went wrong, please try again");
    }
  };

  const sendToInspectorate = async (values) => {
    console.log(values);
    let internalReport = null;
    try {
      internalReport = await createInternalReport({ ...values, report: id });
      alert("Вашето барање е успешно испратено");
    } catch (e) {
      console.log(e);
    }
  };

  console.log(issueDetails.issueData);
  let details = issueDetails.issueData;

  return (
    <Layout>
      <Header></Header>
      <BgImage></BgImage>
      <div className="container mx-auto">
        <div className="text-2xl text-center mt-10 uppercase">
          ДЕТАЉИ О ПРИЈАВИ
        </div>
        <div className="flex flex-row justify-end mt-10 printHide">
          <button
            type="button"
            onClick={() => print()}
            className="py-2 px-4 bg-red text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg "
          >
            Одштампај пријаву
          </button>
        </div>
        <div className="shadow rounded-lg">
          <table className="mt-10 min-w-full leading-normal">
            <thead></thead>
            <tbody>
              {issueDetails.issueData && (
                <>
                  {/* <tr>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      Тип пријаве
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      {reportType}
                    </td>
                  </tr> */}
                  <tr>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      Статус пријаве
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      {reportStatus}
                    </td>
                  </tr>
                  <tr>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      Поднешена анонимно
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      {issueDetails.issueData.anonymous === true ? "Да" : "Не"}
                    </td>
                  </tr>
                  <tr>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      О компанији
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      {issueDetails.issueData.company}
                    </td>
                  </tr>
                  <tr>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      Опис
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      {issueDetails.issueData.description}
                    </td>
                  </tr>
                  <tr>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      Делатност
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      {issueDetails.issueData.industry.name}
                    </td>
                  </tr>
                  {!issueDetails.issueData.anonymous && (
                    <tr>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        Имејл адреса
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        {issueDetails.issueData.email}
                      </td>
                    </tr>
                  )}
                  {!issueDetails.issueData.anonymous && (
                    <tr>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        Име и презиме
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        {issueDetails.issueData.reporter.firstName +
                          " " +
                          issueDetails.issueData.reporter.familyName}
                      </td>
                    </tr>
                  )}
                  {!issueDetails.issueData.anonymous && (
                    <tr>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        Број телефона
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        {issueDetails.issueData.phoneNumber}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      Општина
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      {issueDetails.issueData.municipality.name}
                    </td>
                  </tr>
                  <tr>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      Повреда права
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      {issueDetails.issueData.right_category.title}
                    </td>
                  </tr>
                  <tr>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      Пријављује за себе
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      {issueDetails.issueData.selfReport === true ? "Да" : "Не"}
                    </td>
                  </tr>
                  <tr>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      Члан синдиката
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      {issueDetails.issueData.unionMember === true
                        ? "Да"
                        : "Не"}
                    </td>
                  </tr>
                  <tr>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      Документа
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      {issueDetails.issueData.files.map((file) => {
                        return file.mime.startsWith("image/") ? (
                          <a
                            href={
                              issueDetails.issueData.files.length === 0
                                ? "#"
                                : `${process.env.REACT_APP_API_URL}${file.url}`
                            }
                          >
                            <img
                              alt=""
                              className="w-20 h-20"
                              src={
                                issueDetails.issueData.files.length === 0
                                  ? avatar
                                  : `${process.env.REACT_APP_API_URL}${file.url}`
                              }
                            />
                          </a>
                        ) : (
                          <a
                            className="text-blue"
                            href={`${process.env.REACT_APP_API_URL}${file.url}`}
                          >{`${file.name}`}</a>
                        );
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      Време на ажурирање на пријвата
                    </td>
                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                      {moment(issueDetails.issueData.updated_at).format(
                        "DD.MM.YYYY"
                      )}
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
        <div className="mt-10 printHide">
          <div className="text-2xl mb-5">Статус пријаве</div>
          <Formik
            initialValues={initialValues}
            validateOnChange={false}
            enableReinitialize={true}
            onSubmit={formSubmit}
          >
            <Form>
              <label className="text-gray-700" for="status">
                Промени статус пријаве
              </label>
              <Field
                component="select"
                id="status"
                name="status"
                multiple={false}
                // onChange={handleChangeStatus}
                className="block mt-2 w-52 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
              >
                <option></option>
                <option value={"reported"}>Отворена</option>
                <option value={"in_progress"}>Поступак у току</option>
                <option value={"closed"}>Решена</option>
              </Field>
              <button
                type="submit"
                className="mt-5 mb-10 py-2 px-4 flex justify-center items-center bg-green-600 hover:bg-green-700 focus:green-green-500 focus:ring-offset-green-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg "
              >
                Зачувај нови статус
              </button>
            </Form>
          </Formik>
        </div>
        {/* {details && details.internal_reports.length <= 0 ? (
          <div className="mt-10 printHide">
            <div className="text-2xl mb-5">
              Испрати до Државен инспекторат на труд
            </div>
            <Formik
              initialValues={internalReportInitialValues}
              validateOnChange={false}
              enableReinitialize={true}
              onSubmit={sendToInspectorate}
            >
              {(props) => (
                <Form>
                  <Field
                    name="comment"
                    component="textarea"
                    rows="4"
                    placeholder="Внесете дополнителен коментар тука"
                    className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                  ></Field>
                  <label>Избери основ:</label>
                  <select
                    id="report_basis"
                    name="report_basis"
                    multiple={false}
                    onChange={chooseInternalReportType.bind(
                      null,
                      props.setFieldValue
                    )}
                    // onChange={(e) => {
                    //   props.setFieldValue('content', e.target.value);
                    // }}
                    className="mt-2 block w-72 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                  >
                    <option className="rounded text-gray" value=""></option>
                    <option
                      className="rounded text-gray"
                      value="SECURITY_AND_PROTECTION"
                    >
                      Безбедност и заштита при работа
                    </option>
                    <option
                      className="rounded text-gray"
                      value="RIGHTS_VIOLATION"
                    >
                      Прекршување на работнички права
                    </option>
                    );
                  </select>
                  <button
                    type="submit"
                    className="mt-5 mb-10 py-2 px-4 flex justify-center items-center bg-green-600 hover:bg-green-700 focus:green-green-500 focus:ring-offset-green-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg "
                  >
                    Испрати до Инспекторат
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        ) : (
          <>
            {details && details.internal_reports.length > 0 ? (
              <Link
                to={`/SSMInternalReport/${details.internal_reports[0].id}`}
                className="text-blue"
              >
                Види детали за интерна пријава
              </Link>
            ) : (
              ""
            )}
          </>
        )} */}
        <div className="mt-20 printHide">
          <div className="text-2xl">Комуникација</div>
        </div>
        <div className="mt-5">
          <div className="text-gray-700">
            Одабери понуђени коментар са листе
          </div>
          <Formik
            initialValues={commentInitialValues}
            validateOnChange={false}
            enableReinitialize={true}
            onSubmit={applyComment}
          >
            {(props) => (
              <Form>
                <select
                  id="predefinedComment"
                  name="predefinedComment"
                  multiple={false}
                  onChange={chooseComment.bind(null, props.setFieldValue)}
                  // onChange={(e) => {
                  //   props.setFieldValue('content', e.target.value);
                  // }}
                  className="mt-2 block w-72 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                >
                  <option>--</option>
                  {predefinedComments.map((item, idx) => {
                    return (
                      <option
                        className="rounded text-gray"
                        key={item.id}
                        value={item.content}
                      >
                        {item.title}
                      </option>
                    );
                  })}
                </select>
                <Field
                  name="content"
                  component="textarea"
                  rows="4"
                  placeholder="Унесите додатни коментар овде"
                  className="mt-5 rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                ></Field>
                <label htmlFor="link" className="text-gray-700">
                  Линк
                </label>
                <Field
                  name="link"
                  type="text"
                  placeholder="Унесите линк овде"
                  className="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                ></Field>
                <div className="mt-5">
                  <label htmlFor="files" className="text-gray-700">
                    Прикачи фотографију/документ
                  </label>
                  <input
                    type="file"
                    accept="*"
                    onChange={handleAttachment}
                    className="text-center text-base px-20 py-3 w-4/5 md:w-6/12"
                  />
                </div>
                <button
                  type="submit"
                  className="mt-5 mb-10 py-2 px-4 flex justify-center items-center bg-green-600 hover:bg-green-700 focus:green-green-500 focus:ring-offset-green-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg "
                >
                  Пошаљи коментар
                </button>
              </Form>
            )}
          </Formik>
        </div>
        <div className="mt-10 mb-40">
          <div className="text-2xl mb-3">Листа коментара</div>
          <CommentList comments={comments} deleteComment={deleteComment} />
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  issueDetails: getIssueDetails(state),
  predefinedCommentList: getPredefinedComments(state),
  commentList: getComments(state),
  commentDetails: getCommentDetails(state),
});

const mapDispatchToProps = {
  fetchIssue,
  updateIssue,
  listPredefinedComments,
  listComments,
  createComment,
  deleteComment,
  updateComment,
  createInternalReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(IssueDetails);
