import React, { useEffect } from "react";
import Header from "../../components/Header";
import BgImage from "../../components/BgImage";
import UserList from "../../components/Users/UserList";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import add from "../../img/add.svg";
import Layout from "../../Layout";

import { getUsers } from "../../selectors/user";
import { listUsers, deleteUser } from "../../actions/user";

const AdministratorListPage = ({ listUsers, deleteUser, usersList }) => {
  const { isFetching, isFetched, listData } = usersList;
  const {
    listData: usersListData,
    isFetching: userListFetching,
    isFetched: usersListFetched,
  } = usersList;

  useEffect(() => {
    listUsers({"role.type": 'admin' });
  }, []);

  return (
    <Layout>
      <Header></Header>
      <BgImage></BgImage>
      <div className="container mx-auto">
        <h2 className="text-center text-2xl uppercase leading-tight mt-20 mb-20">
          Администратори
        </h2>
        <div className="text-right">
          <div className="flex justify-end">
            <Link to="/addAdministrator" className="flex flex-row align-items align-middle items-center justify-end">
              <div className="mr-4 text-blue">Додади администратор</div>
              <img
                src={add}
                className="rounded-full bg-blue w-10 h-10 p-3"
                alt="Add button"
              />
            </Link>
          </div>
        </div>
        <UserList users={usersListData} deleteUser={deleteUser} />
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  usersList: getUsers(state),
});

const mapDispatchToProps = {
  listUsers,
  deleteUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdministratorListPage);
